<template>
	<div>
		<table class="table">
			<tbody>
				<tr>
					<th>구분</th>
					<td>
						{{ item.type_name }}
					</td>
				</tr>
				<tr>
					<th>내용</th>
					<td>
						<editor
							v-if="item.content"
							height="440px"
							initialEditType="markdown"
							ref="content"
							class="text-left "
							:initialValue="item.content"
						/>
						<editor
							v-else
							height="440px"
							initialEditType="markdown"
							ref="content"
							class="text-left "
						/>
					</td>
				</tr>
				<tr>
					<th>예약등록</th>
					<td>
						<input v-model="item.date" placeholder="예약일" class="input-box" readonly @click="is_data_pick = !is_data_pick"/>
						<v-date-picker
							v-if="is_data_pick"
							v-model="item.date"
							no-title
							scrollable
							class="position-absolute"
							style="top: 45px; left: -5px"
							@change="is_data_pick = false"
						></v-date-picker>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
	export default {
		name: 'TermsItem'
		,props: ['item']
		,data: function(){
			return {
				is_data_pick: false
			}
		}
	}
</script>